import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import '@/assets/main.scss'
import {ethers} from "ethers";
import {marketProxyCA, nexusToken, nftCA, stakingProxyCA} from "../config";
import nft from "../artifacts/contracts/CryptoRATOriginals.sol/CryptoRATOriginals.json";
import market from '../artifacts/contracts/RatMarketV4.sol/RatMarketV4.json';
import stake from '../artifacts/contracts/NftStakeV3.sol/NftStakeV3.json';
import token from '../artifacts/contracts/Nexus.sol/Nexus.json'

const TESTNET = false;

export let app = createApp(App);
app.config.globalProperties.window = window;
if(TESTNET) {
    app.config.globalProperties.api_url = 'http://localhost:9002';
} else {
    app.config.globalProperties.api_url = 'https://api.cryptor.at';
}
app.config.globalProperties.tokenEnabled = true;

const store = createStore({
    state: {
        connected: false,
        address: null,
        signer: null,
        provider: null,
        nftContract: null,
        marketContract: null,
        stakeContract: null,
        nexusContract: null,
        currentNetwork: null,
        requiredNetwork: TESTNET ? 1337 : 10000,
        stakeEarnings: 0,
        stakePower: 0,
        nexusBalance: 0,
        alphas: [],
        wallet: [],
        lns: [],
        loaded: 0,
    },
    mutations: {
        provider (state, provider) {
            state.provider = provider;
        },
        signer (state, signer) {
            state.signer = signer;
        },
        connect (state, signer) {
            state.connected = true;
            state.signer = signer;
        },
        address (state, address) {
            state.address = address.toLowerCase();
        },
        lns (state, data) {
            state.lns[data.address] = data.name;
        },
        alpha (state, alphaData) {
            state.alphas[alphaData.address] = alphaData;
        },
        nftContract (state, nft) {
            state.nftContract = nft;
        },
        marketContract (state, market) {
            state.marketContract = market;
        },
        stakeContract (state, stake) {
            state.stakeContract = stake;
        },
        nexusContract (state, nexus) {
            state.nexusContract = nexus;
        },
        currentNetwork (state, netId) {
            state.currentNetwork = netId;
        },
        walletNft (state, nftData) {
            nftData.id = parseInt(nftData.id);
            for(var i in state.wallet) {
                if(state.wallet[i].id === nftData.id) {
                    return;
                }
            }
            state.wallet.push(nftData);
            state.wallet.sort(function (a, b) {
                if (a.id === b.id) {
                    return 0;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return -1;
            });
        },
        loaded(state) {
            state.loaded += 1;
        },
        stakeEarnings(state, earnings) {
            state.stakeEarnings = parseFloat(ethers.utils.formatEther(earnings)).toFixed(4);
        },
        nexusBalance(state, balance) {
            state.nexusBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        },
        stakePower(state, power) {
            state.stakePower = power;
        },
    },
    actions: {
        initWallet (state) {
            let defaultProvider = new ethers.providers.JsonRpcProvider('https://smartbch.greyh.at/', "any");
            if(window.ethereum.isConnected()) {
                state.dispatch('connectWallet');
            } else {
                state.commit('provider', defaultProvider);
                return state.dispatch('connectContracts', defaultProvider);
            }
        },
        connectWallet (state) {
            console.log('connectWallet');
            let defaultProvider = new ethers.providers.JsonRpcProvider('https://smartbch.greyh.at/', "any");
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return provider.send("eth_requestAccounts", []).then((accounts) => {
                state.commit('address', accounts[0]);
                return provider.getNetwork().then((netId) => {
                    state.commit('currentNetwork', netId.chainId);
                    state.commit('connect', provider.getSigner());
                    if(netId.chainId === this.state.requiredNetwork) {
                        state.commit('provider', provider);
                        state.dispatch('connectContracts', provider.getSigner())
                        state.dispatch('loadWallet');
                    } else {
                        throw 'Incorrect network';
                    }
                });
            }).catch(() => {
                state.commit('provider', defaultProvider);
                return state.dispatch('connectContracts', defaultProvider);
            });
        },
        connectContracts (state, provider) {
            state.commit('nftContract', new ethers.Contract(nftCA, nft.abi, provider));
            state.commit('marketContract', new ethers.Contract(marketProxyCA, market.abi, provider));
            state.commit('stakeContract', new ethers.Contract(stakingProxyCA, stake.abi, provider));
            state.commit('nexusContract', new ethers.Contract(nexusToken, token.abi, provider));
        },
        switchNetwork(store) {
            return window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: ethers.utils.hexlify(store.state.requiredNetwork)
                }],
            });
        },
        loadLNS(store, address) {
            let normalised = address.toLowerCase();
            if(normalised in store.state.lns) {
                return store.state.lns[normalised];
            }
            const provider = new ethers.providers.JsonRpcProvider('https://smartbch.greyh.at', {
                chainId: 10000,
                ensAddress: '0xCfb86556760d03942EBf1ba88a9870e67D77b627',
            });
            return provider.lookupAddress(normalised).then(name => {
                store.commit('lns', {address: normalised, name: name});
                return name;
            });
        },
        loadAlpha(store, address) {
            if(address in store.state.alphas) {
                return store.state.alphas[address];
            }
            return store.state.nftContract.alpha(address).then((id) => {
                if(id > 0) {
                    return store.state.nftContract.tokenURI(id).then((uri) => {
                        return fetch(uri).then((response) => {
                            return response.json();
                        }).then((result) => {
                            var data = {address: address, id: id.toString(), image: result.image};
                            store.commit('alpha', data);
                            return data;
                        });
                    })
                }
            });
        },
        loadWallet(store) {
            if(store.state.address) {
                store.state.nexusContract.balanceOf(store.state.address).then(balance => {
                    store.commit('nexusBalance', balance);
                });
                store.state.stakeContract.earnings(store.state.address).then(earnings => {
                    store.commit('stakeEarnings', earnings);
                });
                store.state.nftContract.power(store.state.address).then(power => {
                    store.commit('stakePower', power);
                });
                store.state.nftContract.walletOfOwner(store.state.address).then((result) => {
                    let results = result;
                    for (let i in results) {
                        if(parseInt(results[i].toString()) > 0) {
                            store.state.nftContract.tokenURI(results[i]).then((uri) => {
                                return fetch(uri).then((result) => {
                                    return result.json();
                                }).then(data => {
                                    store.commit('loaded');
                                    store.commit('walletNft', {
                                        metadata: data,
                                        id: results[i].toString(),
                                        slot: data.slot,
                                        slotName: data.slotName,
                                        image: data.image,
                                    });
                                });
                            }).catch(() => {
                                return fetch('https://api.cryptor.at/original/nft/' + results[i]).then((result) => {
                                    return result.json();
                                }).then(data => {
                                    store.commit('loaded');
                                    store.commit('walletNft', {
                                        metadata: data,
                                        id: results[i].toString(),
                                        slot: data.slot,
                                        slotName: data.slotName,
                                        image: data.image,
                                    });
                                });
                            });
                        }
                    }
                });
            }
        }
    },
    getters: {
        signer: (state) => state.signer,
        provider: (state) => state.provider
    }
});

app.use(store);
app.use(router);
app.mount('#app')
