<template>
  <div class="container darkmode">
    <div class="row" style="margin-bottom: 0">
      <div class="col-3"></div>
      <div class="col-6">
        <div class="app-title">
          <h1>
            <router-link to="/">Crypto<span style='color: red'>R.AT</span></router-link>
          </h1>
        </div>
      </div>
      <div class="col-3">
        <!--div class="input-group mb-3 align-content-end" style="margin-top:10px;">
          <span class="input-group-text iq">IQ: {{ $store.state.stakePower }} 🧠</span>
          <button class="btn btn-outline-primary">
            {{ $store.state.stakeEarnings }} BCH
          </button>
          <button class="btn btn-primary" @click="claim()" v-if="$store.state.stakeEarnings > 0">
            Claim
          </button>
        </div-->
        <!--
        <div class="input-group mb-3 align-content-end" v-if="tokenEnabled">
          <span class="input-group-text">Balance</span>
          <button class="btn btn-outline-primary">
            {{ $store.state.nexusBalance }} NXS
          </button>
        </div>
        -->
      </div>
    </div>

    <div style="clear:both"></div>
    <div class="row">
      <div class='col-12 menu bar'>
        <router-link to="/">Home</router-link>
        <!--router-link to="/market">Market</router-link-->
        <router-link to="/wallet">My $RATs</router-link>
        <router-link to="/rat/traits">Traits</router-link>
        <router-link to="/rat/landofmischief">Land of mischief</router-link>
        <!--router-link to="/rat/iq">IQ</router-link-->
        <router-link to="/collectibles">Collectibles</router-link>
        <router-link to="/puzzle">Puzzle</router-link>
        <router-link to="/ct">CT Bridge</router-link>
        <div style="width: 200px;display:inline-block" v-if="$store.state.connected">
          <div id="metamask-element">
            <MetamaskAddress v-if="$store.state.address" mm="yes" v-bind:address="$store.state.address" short="yes"></MetamaskAddress>
          </div>
        </div>
        <div style="display:inline-block">
          <span class="metamask-button btn btn-secondary" v-on:click="$store.dispatch('connectWallet')" v-if="!$store.state.connected">Connect</span>
          <span class="metamask-button btn btn-secondary" v-on:click="$store.dispatch('switchNetwork');" v-if="$store.state.requiredNetwork !== $store.state.currentNetwork">Switch network</span>
        </div>
        <div style="width: 200px;padding-left: 2em;display:inline-block">
          <div class="input-group mb-3">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" v-model="theme" value='dark' @change="setTheme">
              <label class="form-check-label"><small>Dark</small></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MetamaskAddress from "@/components/MetamaskAddress";
export default {
  components: {MetamaskAddress},
  data() {
    return {
      ratNumber: null,
      theme: true,
    }
  },
  mounted() {
    var theme = window.localStorage.getItem('theme');
    if(theme) {
      this.theme = theme;
    }
    this.setTheme();
  },
  methods: {
    claim() {
      this.$store.state.stakeContract.harvest().then(() => {
        this.$store.dispatch('loadWallet');
      });
    },
    setTheme: function() {
      var theme = 'light';
      if(this.theme === 'true' || this.theme === true) {
        theme = 'dark';
      }
      window.localStorage.setItem('theme', this.theme);
      document.body.classList.remove('theme--light-theme');
      document.body.classList.remove('theme--dark-theme');
      document.body.classList.add('theme--' + theme + '-theme');
    },
  }
}
</script>