import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue')
  }, {
    path: '/ct',
    name: 'CashTokens Bridge',
    component: () => import('../views/CT.vue')
  }, {
    path: '/slp-ct',
    name: 'CashTokens Bridge (SLP)',
    component: () => import('../views/CTSLP.vue')
  },{
    path: '/collectibles',
    name: 'Collectibles',
    component: () => import('../views/Collectibles.vue')
  },{
    path: '/collectible/:id',
    name: 'Collectible',
    component: () => import('../views/Collectibles/Collectible.vue')
  },{
    path: '/puzzle',
    name: 'Puzzle',
    component: () => import('../views/Puzzle.vue')
  }, {
    path: '/rat/nftv2/:id',
    name: 'NFTv2',
    component: () => import('../views/Rat/Nftv2.vue')
  }, {
    path: '/rat/id/:id',
    name: 'Rat',
    component: () => import('../views/Rat/Id.vue')
  }, {
    path: '/rat/:id',
    name: 'Dressup room',
    component: () => import('../views/Rat/Rat.vue')
  }, {
    path: '/rat/mint',
    name: 'Mint',
    component: () => import('../views/Rat/Mint.vue')
  }, {
    path: '/wallet',
    name: 'My $RATs',
    component: () => import('../views/Wallet.vue')
  }, {
    path: '/rat/find/:query',
    name: 'Find',
    component: () => import('../views/Rat/Find.vue')
  }, {
    path: '/rat/traits',
    name: 'Traits',
    component: () => import('../views/Rat/Traits.vue')
  }, {
    path: '/bridge',
    name: 'Bridge',
    component: () => import('../views/Bridge.vue')
  }, {
    path: '/rat/top',
    name: 'Top owners',
    component: () => import('../views/Rat/Top.vue')
  }, {
    path: '/rat/landofmischief/:address',
    name: 'Land of mischief 1',
    component: () => import('../views/Rat/Mischief.vue')
  }, {
    path: '/rat/landofmischief',
    name: 'Land of mischief 2',
    component: () => import('../views/Rat/Mischief.vue')
  }, {
    path: '/rat/mischief/:address',
    name: 'Land of mischief 3',
    component: () => import('../views/Rat/Mischief.vue')
  }, {
    path: '/rat/mischief',
    name: 'Land of mischief 4',
    component: () => import('../views/Rat/Mischief.vue')
  }, {
    path: '/rat/iq',
    name: 'IQ',
    component: () => import('../views/Rat/Iq.vue')
  }, {
    path: '/market',
    name: 'Market',
    component: () => import('../views/Market/Index.vue')
  }, {
    path: '/:collection/token/:id',
    name: 'Collection token',
    component: () => import('../views/Nft/Token')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
