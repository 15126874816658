<template>
  <Layout></Layout>

  <router-view/>

  <div class="container">
    <div class="row">
      <div class="col-12 text-end">
        <a href="https://shadowkite.io/" target="_blank">
          <img src="@/assets/shadowkite-black-small.png" class="shadowkite-logo-light" />
          <img src="@/assets/shadowkite-white-small.png" class="shadowkite-logo-dark" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: { Layout },
  mounted() {
    this.$store.dispatch('initWallet');
    window.ethereum.on('accountsChanged', () => {
      this.$store.dispatch('connectWallet');
    });
    window.ethereum.on('chainChanged', () => {
      this.$store.dispatch('connectWallet');
    });
  }
}
</script>