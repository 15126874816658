<template>
  <img v-if="mm == 'yes' && !alpha" src="@/assets/metamask.svg" alt="" width="32" height="32">
  <router-link v-if="alpha" v-bind:to="'/rat/id/' + alpha.id" style="cursor: pointer"><img v-bind:src="alpha.image" width="32" height="32" class="alpha-image"/>&nbsp;</router-link>
  <router-link v-if="name != null" v-bind:to="'/rat/landofmischief/' + address">{{ name }}</router-link>
  <router-link v-if="name == null && short != 'yes'" v-bind:to="'/rat/landofmischief/' + address">{{ address }}</router-link>
  <router-link v-if="name == null && short == 'yes'" v-bind:to="'/rat/landofmischief/' + address">{{ address.substr(0, 4) }}...{{ address.substr(address.length - 4, address.length) }}</router-link>
  <span v-if="tag"> ({{ tag }})</span>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      alpha: null,
      tag: null,
    }
  },
  props: ['address', 'short', 'mm'],
  mounted() {
    this.updateDisplay();
  },
  watch: {
    address: function() {
      this.updateDisplay();
    },
    '$store.state.provider': function() {
      this.updateDisplay();
    }
  },
  methods: {
    updateDisplay: function() {
      if(this.address && this.address.substr(0, 2) === '0x' && this.$store.state.nftContract) {
        this.tag = null;
        switch (this.address.toLowerCase()) {
          case '0x657061bf5D268F70eA3eB1BCBeb078234e5Df19d'.toLowerCase():
          case '0x3b968177551a2aD9fc3eA06F2F41d88b22a081F7'.toLowerCase():
            this.tag = 'OASIS';
            break;
          case '0xf62B2E55c73459f1D2464F037121A4dB8b1899f2'.toLowerCase():
          case '0xFDE6ACfA1a79B33Db20Ab95D946A10bc5162Cb3b'.toLowerCase():
            this.tag = 'Market';
            break;
        }
        this.$store.dispatch('loadAlpha', this.address).then((alpha) => {
          this.alpha = alpha;
        });
        this.$store.dispatch('loadLNS', this.address).then((name) => {
          this.name = name;
        });
      }
    }
  }
};
</script>